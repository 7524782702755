/* regular style toast */
@import '~ngx-toastr/toastr';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-custom-solid{
    @apply py-2 px-3 md:px-7 gap-2 rounded-lg border-2 border-customTeal bg-customTeal text-white;
  }

  .btn-custom-bordered{
    @apply py-2 px-3 md:px-7 gap-2 rounded-lg bg-gray-100 border-2 border-customTeal  text-black;
  }

  .btn-custom-bordered-red{
    @apply py-2 px-2 md:px-4 gap-2  border-2 border-red-500 rounded-lg text-black;
  }

  .btn-custom-solid-red{
    @apply py-2 px-3 md:px-7 gap-2 rounded-lg border-2 border-red-500 bg-red-500 text-white;
  }
  .btn-primary{
    @apply bg-customTeal text-white;
  }

}

  /* Fix break for CkEditor heading and bulletpoints clash with tailwind */

/* .ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: inherit;
}

.ck.ck-content ul {
  padding-left: 40px;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
} */

/* Add application styles & imports to this file! */
.tooltip-text {
  position: absolute;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 10px;
  top: -30px;
  left: calc(50% - 50px);
  /* width: 100px; */
}

.tooltip {
  position: relative
}


* {
font-family: 'Source Sans Pro', sans-serif;
}

.ngx-ic-cropper {
  outline: rgb(183, 23, 23) solid 100vw;
  outline: var(--cropper-outline-color, rgb(78, 166, 43)) ;
}


/* styles related to scrollbar */

.app-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: #b5b2b2 0.3s ease;
}

.app-scrollbar::-webkit-scrollbar {
  width: 1px;
}

.app-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  transition: #b5b2b2 0.3s ease;
}

.app-scrollbar:hover {
  scrollbar-color: #e7e7e7 transparent;
}

.app-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-hover);
  border-radius: 5px;
}


/* apply the scrollbar style for all */
/* Apply styles globally */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: #b5b2b2 0.3s ease;
}

*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  transition: #b5b2b2 0.3s ease;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
  border-radius: 5px;
}

/* Adjust styles on hover */
*:hover {
  scrollbar-color: #e7e7e7 transparent;
}

